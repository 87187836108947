import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getDimensions = async () => {
  const response = await axios.get(`${base_url}dimension/`);

  return response.data;
};

const createDimension = async (dimension) => {
  const response = await axios.post(`${base_url}dimension/`, dimension, config);

  return response.data;
};
const updateDimension = async (dimension) => {
  const response = await axios.put(
    `${base_url}dimension/${dimension.id}`,
    { title: dimension.dimensionData.title },
    config
  );

  return response.data;
};
const getDimension = async (id) => {
  const response = await axios.get(`${base_url}dimension/${id}`, config);

  return response.data;
};

const deleteDimension = async (id) => {
  const response = await axios.delete(`${base_url}dimension/${id}`, config);

  return response.data;
};

const dimensionService = {
  getDimensions,
  createDimension,
  getDimension,
  updateDimension,
  deleteDimension,
};

export default dimensionService;
