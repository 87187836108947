import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import materialService from "./materialService";

export const getMaterials = createAsyncThunk(
  "material/get-materials",
  async (thunkAPI) => {
    try {
      return await materialService.getMaterials();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAMaterial = createAsyncThunk(
  "material/get-material",
  async (id, thunkAPI) => {
    try {
      return await materialService.getMaterial(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createMaterial = createAsyncThunk(
  "material/create-material",
  async (materialData, thunkAPI) => {
    try {
      return await materialService.createMaterial(materialData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAMaterial = createAsyncThunk(
  "material/update-material",
  async (material, thunkAPI) => {
    try {
      return await materialService.updateMaterial(material);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAMaterial = createAsyncThunk(
  "material/delete-material",
  async (id, thunkAPI) => {
    try {
      return await materialService.deleteMaterial(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  materials: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const materialSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaterials.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMaterials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.materials = action.payload;
      })
      .addCase(getMaterials.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createMaterial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMaterial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdMaterial = action.payload;
      })
      .addCase(createMaterial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAMaterial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAMaterial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.materialName = action.payload.title;
      })
      .addCase(getAMaterial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAMaterial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAMaterial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedMaterial = action.payload;
      })
      .addCase(updateAMaterial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAMaterial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAMaterial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedMaterial = action.payload;
      })
      .addCase(deleteAMaterial.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default materialSlice.reducer;
