/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
//import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getWarrantys } from "../features/warranty/warrantySlice";
import { getSizes } from "../features/size/sizeSlice";
import { getVolumes } from "../features/volume/volumeSlice";
import { getMaterials } from "../features/material/materialSlice";
import { getDimensions } from "../features/dimension/dimensionSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getWeights } from "../features/weight/weightSlice";
import { getColors } from "../features/color/colorSlice";
import Dropzone from "react-dropzone";
//import "react-widgets/styles.css";
import { Select } from "antd";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { createProducts, resetState } from "../features/product/productSlice";


let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  //description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Brand is Required"),
  category: yup.string().required("Category is Required"),
  tags: yup.string().required("Tag is Required"),
  quantity: yup.number().required("Quantity is Required"),
  color: yup
    .array()
    .min(1, "Pick at least one color")
    .required("Color is Required"),
  size: yup.string().required("Size is Required"),
  material: yup.string().required("Material is Required"),
  dimension: yup.string().required("Dimensions is Required (L,W,H)"),
  volume: yup.string().required("Volume is Required"),
  warranty: yup.string().required("Warranty is Required"),  
  weight: yup.string().required("Weight is Required"),  
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [images,setImages] = useState([]);
  console.log(color);
  useEffect(() => {
    dispatch(getBrands());    
    dispatch(getDimensions());
    dispatch(getVolumes());
    dispatch(getMaterials());
    dispatch(getSizes());
    dispatch(getWarrantys());
    dispatch(getWeights());
    dispatch(getCategories());
    dispatch(getColors());    
  }, []);

  const brandState = useSelector((state) => state?.brand?.brands);
  const sizeState = useSelector((state) => state?.size?.sizes);
  const warrantyState = useSelector((state) => state?.warranty?.warrantys);
  const weightState = useSelector((state) => state?.weight?.weights);
  const dimensionState = useSelector((state) => state?.dimension?.dimensions);
  const volumeState = useSelector((state) => state?.volume?.volumes);
  const materialState = useSelector((state) => state?.material?.materials);
  const catState = useSelector((state) => state?.pCategory?.pCategories);
  const colorState = useSelector((state) => state?.color?.colors);
  const imgState = useSelector((state) => state?.upload?.images);
  const newProduct = useSelector((state) => state?.product); 
  const { isSuccess, isError, isLoading, createdProduct } = newProduct;
  
  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);

  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: i.title,
      value: i._id,
      color:i.title,
    });
  }); 
  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });
console.log(img);

 useEffect(() => {
    formik.values.color = color ? color : " ";
    formik.values.images = img;
  }, [color,img]);
  


  const formik = useFormik({
    initialValues: {
      title: "",
      //description: "",
      price: "",
      brand: "",
      category: "",
      tags: "",
      color: "",
      quantity: "",      
      size:"",
      material:"",
      dimension:"",
      volume:"",
      warranty:"",
      weight:"",
      images: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values));
      //dispatch(uploadImg(values));
      dispatch(createProducts(values));
      formik.resetForm();
      setColor(null);
      setTimeout(() => {
        navigate("/admin/list-product");
        dispatch(resetState());
      }, 3000);
    },
  });
  const handleColors = (e) => {
    setColor(e);
    console.log(color);
  };
 
  return (
    <div>
      <h3 className="mb-4 title">Add Product</h3>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-3 flex-column"
        >
          <CustomInput
            type="text"
            label="Enter Product Title"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
       {/*  <div className="">
            <ReactQuill
              theme="snow"
              name="description"
              onChange={formik.handleChange("description")}
              onBlr={formik.handleBlur("description")}
              value={formik.values.description}
            />
          </div>
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>*/}
          <CustomInput
            type="number"
            label="Enter Product Price"
            name="price"
            onChng={formik.handleChange("price")}
            onBlr={formik.handleBlur("price")}
            val={formik.values.price}
          />
          <div className="error">
            {formik.touched.price && formik.errors.price}
          </div>
          <select
            name="brand"
            onChange={formik.handleChange("brand")}
            onBlur={formik.handleBlur("brand")}
            value={formik.values.brand}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Brand</option>
            {brandState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.brand && formik.errors.brand}
          </div>
          <select
            name="category"
            onChange={formik.handleChange("category")}
            onBlur={formik.handleBlur("category")}
            value={formik.values.category}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Category</option>
            {catState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.category && formik.errors.category}
          </div>
          <select
            name="tags"
            onChange={formik.handleChange("tags")}
            onBlur={formik.handleBlur("tags")}
            value={formik.values.tags}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="" disabled>
              Select Tags
            </option>
            <option value="featured">Featured</option>
            <option value="popular">Popular</option>
            <option value="special">Special</option>
            <option value="special">General</option>
          </select>
          <div className="error">
            {formik.touched.tags && formik.errors.tags}
          </div>         
        <Select
            mode="multiple"
            allowClear
            className="w-100"
            placeholder="Select colors"
            defaultValue={color}
            onChange={(i) => handleColors(i)}
            options={coloropt}
          />
          <div className="error">
            {formik.touched.color && formik.errors.color}
          </div>
          <select
            name="size"
            onChange={formik.handleChange("size")}
            onBlur={formik.handleBlur("size")}
            value={formik.values.size}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select size</option>
            {sizeState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.size && formik.errors.size}
          </div>
          
          <select
            name="material"
            onChange={formik.handleChange("material")}
            onBlur={formik.handleBlur("material")}
            value={formik.values.material}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Material Name</option>
            {materialState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.material && formik.errors.material}
          </div>
          <select
            name="dimension"
            onChange={formik.handleChange("dimension")}
            onBlur={formik.handleBlur("dimension")}
            value={formik.values.dimension}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Dimension</option>
            {dimensionState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.dimension && formik.errors.dimension}
          </div>
          <select
            name="volume"
            onChange={formik.handleChange("volume")}
            onBlur={formik.handleBlur("volume")}
            value={formik.values.volume}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Volume</option>
            {volumeState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.volume && formik.errors.volume}
          </div>
          <select
            name="warranty"
            onChange={formik.handleChange("warranty")}
            onBlur={formik.handleBlur("warranty")}
            value={formik.values.warranty}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Warranty</option>
            {warrantyState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.warranty && formik.errors.warranty}
          </div>
          <select
            name="weight"
            onChange={formik.handleChange("weight")}
            onBlur={formik.handleBlur("weight")}
            value={formik.values.weight}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Select Weight</option>
            {weightState?.map((i, j) => {
              return (
                <option key={j} value={i.title}>
                  {i.title}
                </option>
              );
            })}
          </select>
          <div className="error">
            {formik.touched.weight && formik.errors.weight}
          </div>
          <CustomInput
            type="number"
            label="Enter Product Quantity"
            name="quantity"
            onChng={formik.handleChange("quantity")}
            onBlr={formik.handleBlur("quantity")}
            val={formik.values.quantity}
          />
          <div className="error">
            {formik.touched.quantity && formik.errors.quantity}
          </div> 
         <div className="bg-white border-1 p-5 text-center">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>           
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {imgState?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button 
                    type="button"
                    onClick={() => dispatch(delImg(i.public_id))}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.url} alt="" width={200} height={200} />
                </div>
              );
            })}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit">
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;
