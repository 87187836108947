/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createWeight,
  getAWeight,
  resetState,
  updateAWeight,
} from "../features/weight/weightSlice";

let schema = yup.object().shape({
  title: yup.string().required("Weight Name is Required"),
});
const Addweight = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getWeightId = location.pathname.split("/")[3];
  const newWeight = useSelector((state) => state.weight);
  const {
    isSuccess,
    isError,
    isLoading,
    createdWeight,
    weightName,
    updatedWeight,
  } = newWeight;
  useEffect(() => {
    if (getWeightId !== undefined) {
      dispatch(getAWeight(getWeightId));
    } else {
      dispatch(resetState());
    }
  }, [getWeightId]);

  useEffect(() => {
    if (isSuccess && createdWeight) {
      toast.success("Weight Added Successfullly!");
    }
    if (isSuccess && updatedWeight) {
      toast.success("Weight Updated Successfullly!");
      navigate("/admin/list-weight");
    }

    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: weightName || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getWeightId !== undefined) {
        const data = { id: getWeightId, weightData: values };
        dispatch(updateAWeight(data));
        dispatch(resetState());
      } else {
        dispatch(createWeight(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getWeightId !== undefined ? "Edit" : "Add"} Weight
      </h3>
      <div>
        <form action="" onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
            label="Enter Weight"
            id="weight"
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {getWeightId !== undefined ? "Edit" : "Add"} Weight
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addweight;
