import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import customerReducer from "../features/customers/customerSlice";
import productReducer from "../features/product/productSlice";
import brandReducer from "../features/brand/brandSlice";
import sizeReducer from "../features/size/sizeSlice";
import materialReducer from "../features/material/materialSlice";
import dimensionReducer from "../features/dimension/dimensionSlice";
import volumeReducer from "../features/volume/volumeSlice";
import warrantyReducer from "../features/warranty/warrantySlice";
import pCategoryReducer from "../features/pcategory/pcategorySlice";
import bCategoryReducer from "../features/bcategory/bcategorySlice";
import blogReducer from "../features/blogs/blogSlice";
import colorReducer from "../features/color/colorSlice";
import enquiryReducer from "../features/enquiry/enquirySlice";
import couponReducer from "../features/coupon/couponSlice";
import weightReducer from "../features/weight/weightSlice";
import uploadReducer from "../features/upload/uploadSlice";

//import uploadReducer from "../features/upload/uploadSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    product: productReducer,
    brand: brandReducer,
    size:sizeReducer,
    volume:volumeReducer,
    material:materialReducer,
    dimension:dimensionReducer,
    warranty:warrantyReducer,
    pCategory: pCategoryReducer,
    bCategory: bCategoryReducer,
    blogs: blogReducer,
    color: colorReducer,
    enquiry: enquiryReducer,    
    coupon: couponReducer,
    weight: weightReducer,    
    upload: uploadReducer,
  },
});