import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getVolumes = async () => {
  const response = await axios.get(`${base_url}volume/`);

  return response.data;
};

const createVolume = async (volume) => {
  const response = await axios.post(`${base_url}volume/`, volume, config);

  return response.data;
};
const updateVolume = async (volume) => {
  const response = await axios.put(
    `${base_url}volume/${volume.id}`,
    { title: volume.volumeData.title },
    config
  );

  return response.data;
};
const getVolume = async (id) => {
  const response = await axios.get(`${base_url}volume/${id}`, config);

  return response.data;
};

const deleteVolume = async (id) => {
  const response = await axios.delete(`${base_url}volume/${id}`, config);

  return response.data;
};

const volumeService = {
  getVolumes,
  createVolume,
  getVolume,
  updateVolume,
  deleteVolume,
};

export default volumeService;
