import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import weightService from "./weightService";

export const getWeights = createAsyncThunk(
  "weight/get-weights",
  async (thunkAPI) => {
    try {
      return await weightService.getWeights();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAWeight = createAsyncThunk(
  "weight/get-weight",
  async (id, thunkAPI) => {
    try {
      return await weightService.getWeight(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createWeight = createAsyncThunk(
  "weight/create-weight",
  async (weightData, thunkAPI) => {
    try {
      return await weightService.createWeight(weightData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAWeight = createAsyncThunk(
  "weight/update-weight",
  async (weight, thunkAPI) => {
    try {
      return await weightService.updateWeight(weight);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAWeight = createAsyncThunk(
  "weight/delete-weight",
  async (id, thunkAPI) => {
    try {
      return await weightService.deleteWeight(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  weights: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const weightSlice = createSlice({
  name: "weights",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWeights.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWeights.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.weights = action.payload;
      })
      .addCase(getWeights.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createWeight.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWeight.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdWeight = action.payload;
      })
      .addCase(createWeight.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAWeight.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAWeight.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.weightName = action.payload.title;
      })
      .addCase(getAWeight.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAWeight.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAWeight.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedWeight = action.payload;
      })
      .addCase(updateAWeight.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAWeight.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAWeight.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedWeight = action.payload;
      })
      .addCase(deleteAWeight.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default weightSlice.reducer;
