/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createWarranty,
  getAWarranty,
  resetState,
  updateAWarranty,
} from "../features/warranty/warrantySlice";

let schema = yup.object().shape({
  title: yup.string().required("Warranty Name is Required"),
});
const Addwarranty = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getWarrantyId = location.pathname.split("/")[3];
  const newWarranty = useSelector((state) => state.warranty);
  const {
    isSuccess,
    isError,
    isLoading,
    createdWarranty,
    warrantyName,
    updatedWarranty,
  } = newWarranty;
  useEffect(() => {
    if (getWarrantyId !== undefined) {
      dispatch(getAWarranty(getWarrantyId));
    } else {
      dispatch(resetState());
    }
  }, [getWarrantyId]);

  useEffect(() => {
    if (isSuccess && createdWarranty) {
      toast.success("Warranty Added Successfullly!");
    }
    if (isSuccess && updatedWarranty) {
      toast.success("Warranty Updated Successfullly!");
      navigate("/admin/list-warranty");
    }

    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: warrantyName || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getWarrantyId !== undefined) {
        const data = { id: getWarrantyId, warrantyData: values };
        dispatch(updateAWarranty(data));
        dispatch(resetState());
      } else {
        dispatch(createWarranty(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getWarrantyId !== undefined ? "Edit" : "Add"} Warranty
      </h3>
      <div>
        <form action="" onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
            label="Enter Warranty"
            id="warranty"
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {getWarrantyId !== undefined ? "Edit" : "Add"} Warranty
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addwarranty;
