import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getMaterials = async () => {
  const response = await axios.get(`${base_url}material/`);

  return response.data;
};

const createMaterial = async (material) => {
  const response = await axios.post(`${base_url}material/`, material, config);

  return response.data;
};
const updateMaterial = async (material) => {
  const response = await axios.put(
    `${base_url}material/${material.id}`,
    { title: material.materialData.title },
    config
  );

  return response.data;
};
const getMaterial = async (id) => {
  const response = await axios.get(`${base_url}material/${id}`, config);

  return response.data;
};

const deleteMaterial = async (id) => {
  const response = await axios.delete(`${base_url}material/${id}`, config);

  return response.data;
};

const materialService = {
  getMaterials,
  createMaterial,
  getMaterial,
  updateMaterial,
  deleteMaterial,
};

export default materialService;
