import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getWarrantys = async () => {
  const response = await axios.get(`${base_url}warranty/`);

  return response.data;
};

const createWarranty = async (warranty) => {
  const response = await axios.post(`${base_url}warranty/`, warranty, config);

  return response.data;
};
const updateWarranty = async (warranty) => {
  const response = await axios.put(
    `${base_url}warranty/${warranty.id}`,
    { title: warranty.warrantyData.title },
    config
  );

  return response.data;
};
const getWarranty = async (id) => {
  const response = await axios.get(`${base_url}warranty/${id}`, config);

  return response.data;
};

const deleteWarranty = async (id) => {
  const response = await axios.delete(`${base_url}warranty/${id}`, config);

  return response.data;
};

const warrantyService = {
  getWarrantys,
  createWarranty,
  getWarranty,
  updateWarranty,
  deleteWarranty,
};

export default warrantyService;
