import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import warrantyService from "./warrantyService";

export const getWarrantys = createAsyncThunk(
  "warranty/get-warrantys",
  async (thunkAPI) => {
    try {
      return await warrantyService.getWarrantys();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAWarranty = createAsyncThunk(
  "warranty/get-warranty",
  async (id, thunkAPI) => {
    try {
      return await warrantyService.getWarranty(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createWarranty = createAsyncThunk(
  "warranty/create-warranty",
  async (warrantyData, thunkAPI) => {
    try {
      return await warrantyService.createWarranty(warrantyData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAWarranty = createAsyncThunk(
  "warranty/update-warranty",
  async (warranty, thunkAPI) => {
    try {
      return await warrantyService.updateWarranty(warranty);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAWarranty = createAsyncThunk(
  "warranty/delete-warranty",
  async (id, thunkAPI) => {
    try {
      return await warrantyService.deleteWarranty(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  warrantys: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const warrantySlice = createSlice({
  name: "warrantys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWarrantys.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWarrantys.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.warrantys = action.payload;
      })
      .addCase(getWarrantys.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createWarranty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWarranty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdWarranty = action.payload;
      })
      .addCase(createWarranty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAWarranty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAWarranty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.warrantyName = action.payload.title;
      })
      .addCase(getAWarranty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAWarranty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAWarranty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedWarranty = action.payload;
      })
      .addCase(updateAWarranty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAWarranty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAWarranty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedWarranty = action.payload;
      })
      .addCase(deleteAWarranty.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default warrantySlice.reducer;
