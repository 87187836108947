import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import volumeService from "./volumeService";

export const getVolumes = createAsyncThunk(
  "volume/get-volumes",
  async (thunkAPI) => {
    try {
      return await volumeService.getVolumes();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAVolume = createAsyncThunk(
  "volume/get-volume",
  async (id, thunkAPI) => {
    try {
      return await volumeService.getVolume(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createVolume = createAsyncThunk(
  "volume/create-volume",
  async (volumeData, thunkAPI) => {
    try {
      return await volumeService.createVolume(volumeData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAVolume = createAsyncThunk(
  "volume/update-volume",
  async (volume, thunkAPI) => {
    try {
      return await volumeService.updateVolume(volume);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAVolume = createAsyncThunk(
  "volume/delete-volume",
  async (id, thunkAPI) => {
    try {
      return await volumeService.deleteVolume(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  volumes: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const volumeSlice = createSlice({
  name: "volumes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVolumes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVolumes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.volumes = action.payload;
      })
      .addCase(getVolumes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createVolume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createVolume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdVolume = action.payload;
      })
      .addCase(createVolume.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAVolume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAVolume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.volumeName = action.payload.title;
      })
      .addCase(getAVolume.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAVolume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAVolume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedVolume = action.payload;
      })
      .addCase(updateAVolume.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAVolume.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAVolume.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedVolume = action.payload;
      })
      .addCase(deleteAVolume.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default volumeSlice.reducer;
