import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import dimensionService from "./dimensionService";

export const getDimensions = createAsyncThunk(
  "dimension/get-dimensions",
  async (thunkAPI) => {
    try {
      return await dimensionService.getDimensions();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getADimension = createAsyncThunk(
  "dimension/get-dimension",
  async (id, thunkAPI) => {
    try {
      return await dimensionService.getDimension(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createDimension = createAsyncThunk(
  "dimension/create-dimension",
  async (dimensionData, thunkAPI) => {
    try {
      return await dimensionService.createDimension(dimensionData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateADimension = createAsyncThunk(
  "dimension/update-dimension",
  async (dimension, thunkAPI) => {
    try {
      return await dimensionService.updateDimension(dimension);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteADimension = createAsyncThunk(
  "dimension/delete-dimension",
  async (id, thunkAPI) => {
    try {
      return await dimensionService.deleteDimension(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  dimensions: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const dimensionSlice = createSlice({
  name: "dimensions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDimensions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDimensions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dimensions = action.payload;
      })
      .addCase(getDimensions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createDimension.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDimension.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdDimension = action.payload;
      })
      .addCase(createDimension.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getADimension.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getADimension.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.dimensionName = action.payload.title;
      })
      .addCase(getADimension.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateADimension.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateADimension.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedDimension = action.payload;
      })
      .addCase(updateADimension.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteADimension.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteADimension.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedDimension = action.payload;
      })
      .addCase(deleteADimension.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default dimensionSlice.reducer;
