import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import MainLayout from "./components/MainLayout";
import Enquiries from "./pages/Enquiries";
import Bloglist from "./pages/Bloglist";
import Blogcatlist from "./pages/Blogcatlist";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Colorlist from "./pages/Colorlist";
import Categorylist from "./pages/Categorylist";
import Brandlist from "./pages/Brandlist";
import Sizelist from "./pages/Sizelist";
import Volumelist from "./pages/Volumelist";
import Materiallist from "./pages/Materiallist";
import Dimensionlist from "./pages/Dimensionlist";
import Warrantylist from "./pages/Warrantylist";
import Productlist from "./pages/Productlist";
import Couponlist from "./pages/Couponlist";
import Weightlist from "./pages/Weightlist";
import Addblog from "./pages/Addblog";
import Addblogcat from "./pages/Addblogcat";
import Addcolor from "./pages/Addcolor";
import Addcat from "./pages/Addcat";
import Addbrand from "./pages/Addbrand";
import Addsize from "./pages/Addsize";
import Addvolume from "./pages/Addvolume";
import Adddimension from "./pages/Adddimension";
import Addmaterial from "./pages/Addmaterial";
import Addwarranty from "./pages/Addwarranty";
import Addproduct from "./pages/Addproduct";
import Addweight from "./pages/Addweight";
import AddCoupon from "./pages/AddCoupon";
import ViewEnq from "./pages/ViewEnq";
import ViewOrder from "./pages/ViewOrder";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { OpenRoutes } from "./routing/OpenRoutes";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<OpenRoutes><Login /></OpenRoutes>} />        
        <Route path="/admin" element={<PrivateRoutes><MainLayout /></PrivateRoutes>}>
          <Route index element={<Dashboard />} />
          <Route path="enquiries" element={<Enquiries />} />
          <Route path="enquiries/:id" element={<ViewEnq />} />
          <Route path="blog-list" element={<Bloglist />} />
          <Route path="blog" element={<Addblog />} />
          <Route path="blog/:id" element={<Addblog />} />
          <Route path="coupon-list" element={<Couponlist />} />
          <Route path="coupon" element={<AddCoupon />} />
          <Route path="coupon/:id" element={<AddCoupon />} />
          <Route path="blog-category-list" element={<Blogcatlist />} />
          <Route path="blog-category" element={<Addblogcat />} />
          <Route path="blog-category/:id" element={<Addblogcat />} />
          <Route path="orders" element={<Orders />} />
          <Route path="order/:id" element={<ViewOrder />} />
          <Route path="customers" element={<Customers />} />
          <Route path="list-color" element={<Colorlist />} />
          <Route path="color" element={<Addcolor />} />
          <Route path="color/:id" element={<Addcolor />} />
          <Route path="list-category" element={<Categorylist />} />
          <Route path="category" element={<Addcat />} />
          <Route path="category/:id" element={<Addcat />} />
          <Route path="list-brand" element={<Brandlist />} />
          <Route path="brand" element={<Addbrand />} />
          <Route path="brand/:id" element={<Addbrand />} />
          <Route path="list-size" element={<Sizelist />} />
          <Route path="size" element={<Addsize />} />
          <Route path="size/:id" element={<Addsize />} />
          <Route path="list-material" element={<Materiallist />} />
          <Route path="material" element={<Addmaterial />} />
          <Route path="material/:id" element={<Addmaterial />} />
          <Route path="list-volume" element={<Volumelist />} />
          <Route path="volume" element={<Addvolume />} />
          <Route path="volume/:id" element={<Addvolume />} />
          <Route path="list-dimension" element={<Dimensionlist />} />
          <Route path="dimension" element={<Adddimension />} />
          <Route path="dimension/:id" element={<Adddimension />} />
          <Route path="list-warranty" element={<Warrantylist />} />
          <Route path="warranty" element={<Addwarranty />} />
          <Route path="warranty/:id" element={<Addwarranty />} />
          <Route path="list-product" element={<Productlist />} />
          <Route path="product" element={<Addproduct />} />
          <Route path="list-weight" element={<Weightlist />} />
          <Route path="weight" element={<Addweight />} />
          <Route path="weight/:id" element={<Addweight />} />
          
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
