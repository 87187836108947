import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getWeights = async () => {
  const response = await axios.get(`${base_url}weight/`);

  return response.data;
};

const createWeight = async (weight) => {
  const response = await axios.post(`${base_url}weight/`, weight, config);

  return response.data;
};
const updateWeight = async (weight) => {
  const response = await axios.put(
    `${base_url}weight/${weight.id}`,
    { title: weight.weightData.title },
    config
  );

  return response.data;
};
const getWeight = async (id) => {
  const response = await axios.get(`${base_url}weight/${id}`, config);

  return response.data;
};

const deleteWeight = async (id) => {
  const response = await axios.delete(`${base_url}weight/${id}`, config);

  return response.data;
};

const weightService = {
  getWeights,
  createWeight,
  getWeight,
  updateWeight,
  deleteWeight,
};

export default weightService;
